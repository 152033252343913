<script>
import Layout from '@layouts/public.vue'

export default {
  page: {
    title: 'Home',
  },
  components: { Layout },
}
</script>

<template>
  <Layout>
    <router-view :key="$route.fullPath"></router-view>
  </Layout>
</template>
